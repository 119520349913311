import axios from "axios";
import { BASE_URL } from "./config";

function apiAxios(method: string, url: string, params: any) {
  return new Promise((resolve, reject) => {
    const instance: any = axios.create({
      baseURL: BASE_URL,
      timeout: 6000,
    });

    const isWrite = method === "POST" || method === "PUT";

    // axios.get('', {params})

    const data = isWrite
      ? params
      : {
          params,
        };

    instance[method.toLowerCase()](url, data)
      .then((res: any) => {
        if (res?.status === 200) {
          resolve(res.data);
        } else {
          reject("state code error");
        }
      })
      .catch((err: any) => {
        const errCode = err?.response?.status;

        switch (errCode) {
          case 400:
            break;
          case 401:
            break;
        }

        console.log("[httpClient-err]:", err);
        reject(err);
      });
  });
}
export default {
  get: (url: string, params: any = {}) => {
    return apiAxios("GET", url, params);
  },
  post: (url: string, params: any) => {
    return apiAxios("POST", url, params);
  },
  put: (url: string, params: any) => {
    return apiAxios("PUT", url, params);
  },
  delete: (url: string, params: any) => {
    return apiAxios("DELETE", url, params);
  },
  patch: (url: string, params: any) => {
    return apiAxios("PATCH", url, params);
  },
};
