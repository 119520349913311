import { useFastForwardBlockNumber } from "./useBlockNumber";

type callBackProps = {
  blockNumber: number;
  hash: string;
};
export const useUpDataBlockNumber = () => {
  const updata = useFastForwardBlockNumber();
  const callBack = (data: callBackProps) => {
    if (data.blockNumber) {
      updata(data.blockNumber);
      console.log("data.blockNumber", data.blockNumber);
    }
  };
  return callBack;
};
