import {
  RANK_LIST_BRIDGE,
  RANK_LIST_HOLYGRAIL,
} from "src/helpers/leaderboardConstants";
import HolyGrailBanner from "src/assets/images/leaderboard/banner_holygrail.svg";
import BridgeBanner from "src/assets/images/leaderboard/banner_boardingBridge.svg";
import { SupportedChainId } from "./chainENV/chains.base";

export const VaultUtilsblacklist = [
  "0x276A0cb4973A1527Bc2E9cbD9b278eF8061A4F46",
  "0x3dfC44Ca476527Eb03D7f4B334ac958b201c8019",
  "0x03Fb73dFA117AA4B44C1A624d48FeD2076bf6382",
  "0x08633Ff2b893563a12f53e4a23aaf617318a46B4",
];

export const DOC_LINK = {
  documentation: "https://docs.ede.finance/",
  discord: "https://discord.com/invite/g7GpVVxtxz",
  twitter: "https://twitter.com/ede_finance",
  telegram: "https://t.me/ede_finance",
  medium: "https://medium.com/@ede_finance",
  github: "https://github.com/El-Dorado-Exchange",
  more: "https://docs.ede.finance/tokenomics/the-elp-pools",
};

export const PRICE_CHART_URL: { [key: number]: string } = {
  [SupportedChainId.BSCMAINNET]: "https://data.ede.finance/bsc/edekline",
  [SupportedChainId.ARBITRUM]: "https://data.ede.finance/arb/edekline",
  [SupportedChainId.ARB_TEST]: "https://data.ede.finance/arb/edekline",
  [SupportedChainId.BSCTEST]: "https://data.ede.finance/bsc/edekline",
};
export const COMMUNITY_ROUTER_OBJ = {
  "/Leaderboard/HolyGrail": {
    bannerImg: HolyGrailBanner,
    rankList: RANK_LIST_HOLYGRAIL,
    name: "holygrail",
  },
  "/Leaderboard/BoardingBridge": {
    bannerImg: BridgeBanner,
    rankList: RANK_LIST_BRIDGE,
    name: "boardingbridge",
  },
};

export const DEFAULT_URL = "https://api.ede.finance";

// 网络请求轮询间隔
export const HTTP_POLL_DELAY = 30000;
// 网络轮询队列元素请求间隔
export const HTTP_QUEUEQUERY_DELAY = 500;
