import { useDispatch } from "react-redux";
import { getPrices } from "src/services/api";
import { useAppSelector } from "../hooks";
import { priceList } from "./actions";
interface Response {
  code: number | string;
  data: any;
}
export function useQueryPrice() {
  const dispatch = useDispatch();
  return () => {
    getPrices().then((res: Response) => {
      const data = res || {};
      dispatch(priceList(data));
    });
  };
}

export function usePrices() {
  return useAppSelector(({ http: { price } }) => price);
}
