import { createReducer } from "@reduxjs/toolkit";
import { priceList } from "./actions";

type HttpState = {
  price?: {
    ETHUSDT: string;
  };
};

const initialState: HttpState = {
  price: undefined,
};

export default createReducer(initialState, (builder) =>
  builder.addCase(priceList, (state, { payload: data }) => {
    state.price = data;
  })
);
