interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  97: {
    NATIVE_TOKEN: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    WBNB: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
    BTC: "0x34A601cb81ffE8941e24f303306b13A859CCeE13",
    ETH: "0xDf1BD718CABB900bc6f705ed8b9c9F568e3D3AD7",
    BUSD: "0x83aD7A3C55f40a00B53726dC114443d245d9ec48",
    USDT: "0x96562f0FB4f7a8e1F7F3fFa1A685C476BF4815b4",
    DOGE: "0x4443DA4293db262AA4dDB2bc65006f2e7b2E3cE1",
    XRP: "0x3A31dC5B6b691f5585a7Ae88A19bb9Eb2e441C67",
    ADA: "0xbAe6d2daa895C15eBC0bB1E3af393797f2c77C1b",
    LINK: "0xbe263962BE1bB42579788F671f523A9917eA84C3",
    CAKE: "0xee967d773c257fC333a8133c478e9DE831D3Cb3C",
    DOT: "0x5006914a9Ed46B68f2Ea2C71CA0dd395Bf1530C3",
    FIL: "0xb3AE87b0ba8b5F7f1Ae4A2f46131D6e891053E24",
    GMT: "0x44F6B5E8925CAcC923a1b6A744CE3337F07d326c",
    INJ: "0xD79a6BC7D373eAeE5Ee10c8568Cb9d554C68cF3B",
    LTC: "0x42394ECe13776A1c92F28732AB870a5640B60Bee",
    MATIC: "0x4Dd4ffE010E6A50CBF53C49a061D6b1c44cc0E54",
    MBOX: "0xDFCCfa814e092D892F1b9AE03715E3b548dcE8B8",
    REEF: "0x9f0FAf7EAA50aaCfE537dddd5e5f10Cd487dfB6E",
    SXP: "0xeef32719C87eA0e71925f6cdc37A2E1D684F770B",
    TRX: "0x63aDCec442398d3d10733A8EA12D0Cb944D9Bc3a",
    XAU: "0x1f015c77471CdF23635eBFCD2175102f0d8730AF",
    XVS: "0xd02CC3e48e75DA7E954f5Dce010B679C0f066403",
    chainlinkUSDT: "0xEca2605f0BCF2BA5966372C99837b1F182d3D620",
    chainlinkBUSD: "0x9331b55D9830EF609A2aBCfAc0FBCE050A52fdEa",
    chainlinkBTC: "0x5741306c21795FdCBb9b265Ea0255F499DFe515C",
    chainlinkBNB: "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526",
    chainlinkWBNB: "0x2514895c72f50D8bd4B4F9b1110F0D6bD2c97526",
    chainlinkETH: "0x143db3CEEfbdfe5631aDD3E50f7614B6ba708BA7",

    chainlinkXRP: "0x4046332373C24Aed1dC8bAd489A04E187833B28d",
    chainlinkDOGE: "0x963D5e7f285Cc84ed566C486c3c1bC911291be38",
    chainlinkDOT: "0xEA8731FD0685DB8AeAde9EcAE90C4fdf1d8164ed",
    chainlinkLINK: "0x1B329402Cb1825C6F30A0d92aB9E2862BE47333f",
    chainlinkCAKE: "0x81faeDDfeBc2F8Ac524327d70Cf913001732224C",
    chainlinkADA: "0x5e66a1775BbC249b5D51C13d29245522582E671C",
    chainlinkFIL: "0x17308A18d4a50377A4E1C37baaD424360025C74D",
    chainlinkGMT: "0x0EAeCDf64C9e8dC709d3B453f921D97c02B4515F",
    chainlinkINJ: "0x58b299Fa027E1d9514dBbEeBA7944FD744553d61",
    chainlinkLTC: "0x9Dcf949BCA2F4A8a62350E0065d18902eE87Dca3",
    chainlinkMATIC: "0x957Eb0316f02ba4a9De3D308742eefd44a3c1719",
    chainlinkMBOX: "0x920667ED2615bc053A4b156aCD48919D83F997cF",
    chainlinkREEF: "0x902fA2495a8c5E89F7496F91678b8CBb53226D06",
    chainlinkSXP: "0x678AC35ACbcE272651874E782DB5343F9B8a7D66",
    chainlinkTRX: "0x135deD16bFFEB51E01afab45362D3C4be31AA2B0",
    chainlinkXAU: "0x4E08A779a85d28Cc96515379903A6029487CEbA0",
    chainlinkXVS: "0xCfA786C17d6739CBC702693F23cA4417B5945491",
    USDX: "0x85a723335Fa7CE168f14E919E5706A2C13670783",
    VaultPriceFeedV2Fast: "0xb9Ba2ccD51bE5329b17983BDBB2d3B871C6A90CC",
    randomSource: "0xdF07Ab42a4Ad332D06D6a254e2B384Ddb7bcbda5",
    NFTUtils: "0x47CBf437D3D19819FCA8C632988CF8d36F063Fdf",
    InfoHelper: "0x9055BCD77451Ab6D0104C17D7a1EF68f2Da719C5",
    Reader: "0x678f37C83a8f67Dbea834F86d3832eFB4b1AA9b9",
    VaultReader: "0x176ed30f4A3DC9032Cac2b2Cd2F92bA4fBBAEa8F",
    PositionReader: "0xC4440F533d1000EE2Fba03ddd25D7B47Cb9FC366",
    OrderBookReader: "0x696f2A98F30339B57bFa01bEf167666Ef6d720BD",
    EDE: "0x822aD805F00559F6e7FA655A69A9a58f75CEebA8",
    Timelock: "0xEBaAA73824F263A18b78fD4758467E8AB440ba70",
    alphaEDE: "0x16956E98b63d11cF59a0491a456aA40b0a05b069",
    aEDE: "0x16956E98b63d11cF59a0491a456aA40b0a05b069",
    pEDE: "0xb8C897e310a657E277183Aa99D9Bf42401D9403e",
    EUSD: "0x5364F5952000ce9b2E6ad3aE387bD87a484DE11c",
    RewardRouter: "0x3E89B2DBce49e8739dDED9B5de0Fa4b852F35C70",
    ESBT: "0x0834A70158b9497a440B8Efddb8aa1b67F211A1b",

    // ELP-1p
    ELP_1p: "0xf35DF07Cbd2B04A4996D7A2a480091e9f6b96065",
    Vault_ELP_1p: "0x36f2e99f4c1d8D8D33b46Aa80fF6ee77822a2C00",
    VaultUtils_ELP_1p: "0x6d770201A1f2451a7F2625eE91E960fF424327a0",
    VaultStorage_ELP_1p: "0x8AB9883Cb778675e93946a831533E303aa29a7A7",
    Router_ELP_1p: "0x003E9f5B79e958A90883832f680cF83adb59b2Cf",
    OrderBook_ELP_1p: "0xB04468FB5b27B6792193D7a864753df7F83Ae959",
    PositionManager_ELP_1p: "0xe55020B188eda89865941717fe5f09c0002D1caD",
    PositionRouter_ELP_1p: "0x181Da883FDF9B0926324848a507404A40A492720",
    ElpManager_ELP_1p: "0x51e914457B53952DD966208De4459C7Ab214b3f5",
    RewardTracker_ELP_1p: "0xCB405eD5B6337FCb0468C6EA8bc68Cb8628c72f9",
    RouterSign_ELP_1p: "0x1B3D25f9a5ce8bbfc36fBe16Ec29f6EFe0689029",

    // ELP-3
    ELP_3: "0xb93Ed30f65DeA0395e7279b7eb3feCe1f9989FFd",
    Vault_ELP_3: "0x43Be79bd3b74A7f505341b685a7b23ebBc530F8B",
    VaultUtils_ELP_3: "0xd28FAE7Da9415F99684702d42AD3cD39041400AC",
    VaultStorage_ELP_3: "0x7b9fD99bc4d07ca1ceE1AB46920b689b98373Ffa",
    Router_ELP_3: "0x4CBAC569A0444e215074eB840430056346C5C1A3",
    OrderBook_ELP_3: "0xfAeef0e00C8a5e9c254554871C3c5Fae9e23032a",
    PositionManager_ELP_3: "0xB9EC8ad983548d0aF1b7dC714BeF1D8FcF72E7fd",
    PositionRouter_ELP_3: "0xF9Ed435AC168532dABe114c659C3766Be4c84162",
    ElpManager_ELP_3: "0x7519FA951d56555FfA3c3467070cA0aEd98A6916",
    RewardTracker_ELP_3: "0x8F587911e355eCA9537A6576dbd947FdbAaEbCA4",
    RouterSign_ELP_3: "0xEdD1CA00B2712c023D94b9463d14F83c152621fD",

    veStaker: "0xB7d54a23f5f2497Cf4F13D352Db1E392017dE46e",
    edeDistribution: "0x0d8732FA00d46287C70F04ec2d082248e7755c90",
    eusdDistribution: "0x24a5d1832064631A776e9eEcf775CD60e0a1e61C",
    stakeHelper: "0x566640f92d67Bbe412C6Bfb00812Fcf06EACac95",
    TokenVesting_EDE: "0x67E00dAA2490c7F059Ab1E76E72cBdC05358421A",
    TokenVesting_pEDE: "0xd074DA02677DAdFA3452C47438b7b0992CDdDC1d",
    RewardRouterHelper: "0x31bC1F5b3927b470665F106789D47Eba59D8D56F",
    BurnToScore: "0x9F52A4af263532EB8F8Fa7E805645463Ed6f0692",
    TradeRebate: "0x1bd057Bf7b2Bba1eDe1d8f049a7C734cc877EfFf",

    faucet: "0xC47503aF9C1c672A7c0Aa434981dA99d68bcFA73",

    Team: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    ede_burn: "0x0000000000000000000000000000000000000001",
    Marketing: "0x0000000000000000000000000000000000000001",
    TokenVesting_pEDE2: "0x0000000000000000000000000000000000000001",
    LP_Farm: "0x0000000000000000000000000000000000000001",
  },
  56: {
    deployer: "",
    NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    WBNB: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    BTC: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
    ETH: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
    BUSD: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    USDT: "0x55d398326f99059fF775485246999027B3197955",
    DOGE: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
    DOT: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
    XRP: "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
    CAKE: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
    LINK: "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
    ADA: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
    chainlinkBUSD: "0xcbb98864ef56e9042e7d2efef76141f15731b82f",
    chainlinkBTC: "0x264990fbd0a4796a3e3d8e37c4d5f87a3aca5ebf",
    chainlinkBNB: "0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee",
    chainlinkWBNB: "0x0567f2323251f0aab15c8dfb1967e4e8a7d42aee",
    chainlinkETH: "0x9ef1b8c0e4f7dc8bf5719ea496883dc6401d5b2e",
    chainlinkXRP: "0x93a67d414896a280bf8ffb3b389fe3686e014fda",
    chainlinkDOGE: "0x3ab0a0d137d4f946fbb19eecc6e92e64660231c8",
    chainlinkDOT: "0xc333eb0086309a16aa7c8308dfd32c8bba0a2592",
    chainlinkLINK: "0xca236e327f629f9fc2c30a4e95775ebf0b89fac8",
    chainlinkCAKE: "0xb6064ed41d4f67e353768aa239ca86f4f73665a1",
    chainlinkADA: "0xa767f745331D267c7751297D982b050c93985627",
    vaultPriceFeedV2Fast: "0x155B1D516b6dd0F9A69f892c02dB443E7f7Cf9FF",
    pEDE: "0xaa4395A1879D51607944610E23eD53BffE3803a5",
    aEDE: "0x43F649919f4ac48874D7f65D361702E4447Dec0c",
    EDE: "0x4136129Ac3aC90cF9817548b24D35E73E9457e05",
    EUSD: "0x691390b8505821e9f62f7F848dD7C20d5205a58F",
    InfoHelper: "0xF0E9bC99C29CE532e764791d4Bcfc86e976d2d84",
    Reader: "0x86aF1E551C081eC2269f62708C291af1627fA4ED",
    VaultReader: "0x931878ae8A049B8a9116d455911543735E0F09cB",
    OrderBookReader: "0xe1ca1D89612c5df6Ec37204164AD655366Ec3f9c",
    ESBT: "0x70d9c5473D8cFf115E0213B20fd2eEE61F46F877",
    InfoCenter: "0xB00885eef0610C1A9D0f4c125Abe959B63F6B2BF",
    RewardRouter: "0x2108397905F6d3A9b277c545948C6d6E1Ca22D06",
    RewardRouterHelper: "0xda7a91447D4c0842B395570C830886C5646Fd726",
    Timelock: "0x12A8aeF0f1B3af754359bb6Df0F6eE19C58E330D",
    USDX: "0x2485B41075542aadbA0A5cbBA369678bC7A94c0e",
    ElpTransferHelper: "0xb69bA67a007F73eC37db2ea16BEF9386e25B980f",

    ELP_1p: "0xC25baf0828B4dEb0a9bC7CC918cFA16BA875a4be",
    Vault_ELP_1p: "0xF1D7e3f06aF6EE68E22baFd37E6a67b1757c35a9",
    VaultUtils_ELP_1p: "0xba97F52714c80C4642FAD2EbC10486f7AA0C5e1d",
    VaultStorage_ELP_1p: "0x693C116Ae5dA43EB08dda6954416b3144085880C",
    ElpManager_ELP_1p: "0xFaF4bc3791B7B2133564155482abd190d971f055",
    RouterSign_ELP_1p: "0x6B0513cDFfD0c74C6aC6B44776d0ef388dC8D751",
    Router_ELP_1p: "0x9695862790E00A4cb80f474103078d1EEEe916fA",
    OrderBook_ELP_1p: "0x001D4da8A1DCC9B44f3E9b4Af91f1F603D04cC08",
    PositionManager_ELP_1p: "0x59F4aC9fd1f465Af1dE49d0Fbe90c7696281F43A",
    PositionRouter_ELP_1p: "0x19445971fA50014E5434b0F9B8929730eFbA44Fa",
    RewardTracker_ELP_1p: "0x43c1FcC7F4E604F7DA57bA58Bb2A8E7d9cc48B21",

    ELP: "0x28b31aB12A285648505568897D55778174BEDa1E",
    Vault: "0x7f90C8De425e2E21F6d152e881713DE5Fe37dEAB",
    VaultUtils: "0x3dfC44Ca476527Eb03D7f4B334ac958b201c8019",
    Router: "0x6F9AaE42c4bfa604f91795dD50aEd643766aFb84",
    OrderBook: "0xF08a5Cc5f9432f684A30E9aADE3d51A516b56178",
    PositionManager: "0x7E1C4a0D16a56bC05F03BE39812E6b4A75c8A963",
    PositionRouter: "0xDeA8e4910872B0761262b67CaFF365d98F7911BE",
    ElpManager: "0xa0297244063a75AB2fd1457DCbb2A585fC82fc03",
    RewardTracker: "0x68DfAEE9D90F6B7B7Cd643Cd179849C036Ae3FF7",

    ELP_2: "0x1C5AFF95D1C701284E595A8bE675Cf6d474C0069",
    Vault_ELP_2: "0x2c7077cF9bd07C3BC45B4E5b8C27f8B95c6550B3",
    VaultUtils_ELP_2: "0x08633Ff2b893563a12f53e4a23aaf617318a46B4",
    Router_ELP_2: "0x7569dF80D47321f2bAFEDba0De5302761940eeC9",
    OrderBook_ELP_2: "0x80E1BbAddAb5A13Cc1d6954041B9002986Ec24C8",
    PositionManager_ELP_2: "0xF19f56C73B69caFeD9c854aDDDB16cB2bc350cfa",
    PositionRouter_ELP_2: "0xb8cF970D76E473973b8DA6FD1A4B9294a5C49c81",
    ElpManager_ELP_2: "0x260ceD7C2233BB3E4512b20B4eA3351D155Dc517",
    RewardTracker_ELP_2: "0x90fb9475BAf9BbCC3ea3468F5C2a9D3A6001032F",

    veStaker: "0xe77cA7549567d9b231238977646009b25D2aa4bB",
    edeDistribution: "0xFAB1c6C270E9297d4cE79A036d5C1772d844f2C8",
    eusdDistribution: "0x00d2dB2B4F30b1F77bDEb57C8Aa89D1732FD607D",
    stakeHelper: "0x36A294739Ca7865CC7CE496089F2621273C300f1",
    TokenVesting_EDE: "0x6517c8Ad9A160476c325d065a213D6A887263B07",
    TokenVesting_pEDE: "0x7ACc245ddBFb3d8beFD4AEfc5B1b687e776180dC",
    randomSource: "0x9ecb49340c3f29e1517244579782b8b693f9b28b",
    ShareholdersRebate: "0x8027C3155ab9bb0b663e9d835A68f3Abad7aD20c",
    NFTUtils: "0x44D8a4e73A25860d9d61Cb8e0Efc48701656eE82",
    DataFeed: "0x23Cfbf835e6D4Ab8A45dCa7115869e88cbA5957D",
    FundCreator: "0x2BAe69F0d73241E20b477e4b6e87E964b84E2EB7",
    FQUtilCreator: "0xCCD3891c1452b7CB0E4632774B9365DC4eE24f20",
    EDE_LP: "0xD14E0b9EbA0010e97f57CBd9042215499b53Bc47",
    TradeRebate: "0x5CE96AEc9E8DF812bF1Df6925Ca56C2d57B051ac",
    PositionReader: "0x53198bC825Bcd438D181F2902eFbC4Ba1ce2D62E",
    RouterSign_ELP: "0x6B0513cDFfD0c74C6aC6B44776d0ef388dC8D751",
    Team: "0x51e496e5e9f33a055655c275f0BA9f550771D921",
    LP_Marketing: "0x3EB04DDD24D232666c0992491f33DBD09Fc0440F",
    Contracts_Reserve: "0x1Dc4396496a809788F1dCD4F19B8515d90FCBE70",
    ede_burn: "0x000000000000000000000000000000000000dEaD",
    Marketing: "0xAF36E340E901c9864f7f215B9DeAc00aa87e4ea3",
    TokenVesting_pEDE2: "0x2bB5FBDB9045E8b6f7b08B89E02545D780A8b421",
    BurnToScore: "0x9A70AB073A7Bd9129D1F9071735789a6473cC467",
    LP_Farm: "0x0000000000000000000000000000000000000001",
  },
  421613: {
    deployer: "0x9fC9bd6a1605e78bA4556f0098442aB93E78FC0D",
    NATIVE_TOKEN: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    WETH: "0xEe01c0CD76354C383B8c7B4e65EA88D00B06f36f",
    BTC: "0x16Fa53e7C585377Eb020622B73FbACBa88ba3a2b",
    USDC: "0x470AC774bb8327Fde0fa0565e2680540B28A3341",
    USDT: "0x50aAF50E4DA16aa5b1F12eAF48a62d093CAfd61E",
    DAI: "0x0c9dF6B22DcC519B7e1073B2C3199B69E1994a59",
    chainlinkBTC: "0x6550bc2301936011c1334555e62A87705A81C12C",
    chainlinkDAI: "0x103b53E977DA6E4Fa92f76369c8b7e20E7fb7fe1",
    chainlinkETH: "0x62CAe0FA2da220f43a51F86Db2EDb36DcA9A5A08",
    chainlinkWETH: "0x62CAe0FA2da220f43a51F86Db2EDb36DcA9A5A08",
    chainlinkLINK: "0xd28Ba6CA3bB72bF371b80a2a0a33cBcf9073C954",
    chainlinkUSDC: "0x1692Bdd32F31b831caAc1b0c9fAF68613682813b",
    chainlinkUSDT: "0x0a023a3423D9b27A0BE48c768CCF2dD7877fEf5E",
    VaultPriceFeedV2Fast: "0x5de4EC36511B638293D708B1669a484d906Ab925",
    randomSource: "0x9F653079A849aC67E5C8E6220Fc0Aa95b05E553b",
    NFTUtils: "0x4b07844ccE366806E8772b462b09d6361a87c263",
    InfoHelper: "0xED227229202780E84F05c7B4431911A9A31a62F3",
    Reader: "0xAad4C29f08ea791abEA14ea8707ae05E6CF9820A",
    OrderBookReader: "0x48a4192E8650D527295C3CDBa3E6fcccf1bC449B",
    VaultReader: "0x98B2BE6e3cfb1058Dc1345aF94990eadc86BEccA",
    PositionReader: "0x8d09Aa994D788a10Be972b61875b7eDACf04A549",
    Timelock: "0xC886Bf36d3e422df347CAbfd3215AEd02328D8aE",
    USDX: "0x713136320704AEee48d0dC2772D01260932d4506",
    EDE: "0xB2F97c0f066893E56A9Db65987C98Fb53e412072",
    alphaEDE: "0xb135fe02078556324551F27F3699cfb6cd80827e",
    aEDE: "0xb135fe02078556324551F27F3699cfb6cd80827e",
    pEDE: "0x795C381cc335C092B322c94Ffa440784f4Fc6376",
    EUSD: "0x76e5c718c29d52b4D66aEBC05C5C3Db6e0bA664C",
    RewardRouter: "0x565B265277574ED66715419c4767f0953265fe50",
    ESBT: "0xF2A514f497C0cbBC7dAc6f1931b25F7B5394f299",
    ELP: "0x5D237C21C2f44722bC037F1AE9Ddbe66bF28FEbd",
    Vault: "0x36d41b6fF22EC7959f910d4F26C4Fbe6e5D6Ac4E",
    VaultUtils: "0x2FEc7c700308Fc98671DA41ba793275035B5df77",
    VaultStorage: "0xb113A7cEa58568ABdd0553Df4c8bB9B23b042276",
    Router: "0x6Df901183841f2F53a3F78Bf8e57A20c6d460fb0",
    OrderBook: "0xCB67C521340a5Bb6DF420aC2289e7aBB7a4e9025",
    PositionManager: "0xb898c5F960F3eFb5d5177620fBfE5bf7da0b6825",
    PositionRouter: "0x30dA900dB2D2d157762efa3efF6956d40Ca09EaF",
    ElpManager: "0x4ee868Da9eED11EB0E666CDB0B81731CbdE21748",
    RewardTracker: "0x072958D4930Cf118fe3320d04c9e0a02f1cfc918",
    veStaker: "0x3f3b52b7E25C4c64A97747960c766225BA1015C0",
    edeDistribution: "0x1607AeeCEEb665A0623fF7c7810B67Fb09EF677c",
    eusdDistribution: "0xB870777498e54296100435E3402f02e37322E95B",
    stakeHelper: "0x1291C3f68C2f0619622337EEA2Aa8c8A956E9F1b",
    TokenVesting_EDE: "0xa49daB0C49c4fE83F033Af152A32D3e863e8bB86",
    TokenVesting_pEDE: "0x3D65cE3DeD32307dB91E656a807297B3Fb74E031",
    RewardRouterHelper: "0x27C5721B4BF3c690f2c42e33B128550AAaA2889E",
    BurnToScore: "0x7f571b26721579a78F3Aa3536544F2D2D9B68570",
    TradeRebate: "0xE414afBFa3185a5b1257545B9916222Ba083b8b9",
    faucet: "0x59cedd4E2B5425e784487F3bf4f898693BdCBa80",

    EDE_LP: "0xaEe316F5Fd919ED7347597Ee5B93d76917a02e48",
    Team: "0x0000000000000000000000000000000000000001",
    LP_Marketing: "0x0000000000000000000000000000000000000001",
    Contracts_Reserve: "0x0000000000000000000000000000000000000001",
    ede_burn: "0x0000000000000000000000000000000000000001",
    Marketing: "0x0000000000000000000000000000000000000001",
    TokenVesting_pEDE2: "0x0000000000000000000000000000000000000001",
    LP_Farm: "0x0000000000000000000000000000000000000001",
  },
  42161: {
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    WETH: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    BTC: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
    USDC: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    USDT: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    DAI: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    ARB: "0x912CE59144191C1204E64559FE8253a0e49E6548",
    chainlinkBTC: "0x6ce185860a4963106506c203335a2910413708e9",
    chainlinkDAI: "0xc5c8e77b397e531b8ec06bfb0048328b30e9ecfb",
    chainlinkETH: "0x639fe6ab55c921f74e7fac1ee960c0b6293ba612",
    chainlinkWETH: "0x639fe6ab55c921f74e7fac1ee960c0b6293ba612",
    chainlinkUSDC: "0x50834f3163758fcc1df9973b6e91f0f0f0434ad3",
    chainlinkUSDT: "0x3f3f5df88dc9f13eac63df89ec16ef6e7e25dde7",
    VaultPriceFeedV2Fast: "0x046600975BED388D368F843A67E41545e27A2591",
    randomSource: "0xc64e1b4D46d431De799660171f6992c23e321DB9",
    NFTUtils: "0xd25ce0f789A58Eb5DC3Fa1cA9963D83b673800b7",
    InfoHelper: "0x16a6bd70684c0626bc3c69eF62EF5860849df851",
    Reader: "0x44D8a4e73A25860d9d61Cb8e0Efc48701656eE82",
    VaultReader: "0x12B0fc0fA0f2f8Df188681effc6E7fE363aE2283",
    PositionReader: "0x12A8aeF0f1B3af754359bb6Df0F6eE19C58E330D",
    OrderBookReader: "0x2BAe69F0d73241E20b477e4b6e87E964b84E2EB7",
    Timelock: "0x0142FeF53eBB44Ed2C00c16478DC619fc3e64321",
    EUSD: "0xB00885eef0610C1A9D0f4c125Abe959B63F6B2BF",
    RewardRouter: "0x86aF1E551C081eC2269f62708C291af1627fA4ED",
    ESBT: "0x197E2e5a16F63dBB7BB2AE55975ff49b835f11e2",
    aEDE: "0x5566d132324181427eD4f46989121030BC6689C7",
    alphaEDE: "0x5566d132324181427eD4f46989121030BC6689C7",
    pEDE: "0x496900EabcDF5544507ED29Cda3c7f9d0aCE7208",
    EDE: "0xCCD3891c1452b7CB0E4632774B9365DC4eE24f20",
    USDX: "0xC8b346Cd04D367bbb234732F36Ee6a5bE6a54c8f",
    ELP: "0xEc08B5a75473FD581BE6628d4e2Ed08b49078dF0",
    Vault: "0xFC36bE177868b05F966E57bfc01617501b1f6926",
    VaultUtils: "0xb23Cd479195c3Ad24c569C5e1f8641Da5a4b3CE4",
    VaultStorage: "0x5655fBeb5e066AE5fcCe763ed21daC39Bd3B144C",
    Router: "0x2c7077cF9bd07C3BC45B4E5b8C27f8B95c6550B3",
    OrderBook: "0x08633Ff2b893563a12f53e4a23aaf617318a46B4",
    PositionManager: "0x7569dF80D47321f2bAFEDba0De5302761940eeC9",
    PositionRouter: "0x80E1BbAddAb5A13Cc1d6954041B9002986Ec24C8",
    ElpManager: "0x26AA71BE9CCD794a4c9043Be026c68496b45Aa73",
    RewardTracker: "0x2108397905F6d3A9b277c545948C6d6E1Ca22D06",
    veStaker: "0x39Eb2A5196a00186D15c28E9fe35A36fD1e6f06c",
    edeDistribution: "0x9321061001f07420bF1F068d46feC6E5485690A2",
    eusdDistribution: "0xE3CA515c9C430f2E270E293ab2793070014F720d",
    stakeHelper: "0x494b1C5Db70F0e4fE577A53685184B47279C5901",
    RewardRouterHelper: "0x881DB48147c014111ef2aC6587E3cF2d9206171d",
    BurnToScore: "0xe8594Bb25fb88bBefd61D219Ffbd32a0de77b383",
    TradeRebate: "0x1c547fBa2C20BB5d432970947b4849143A8E4fF8",
    TokenVesting_EDE: "0x2380B6F99266121FFaF0c457cFAdcd0dc5846560",
    TokenVesting_pEDE: "0x872b327cc9494a31C87134b5162525B7224C525E",
    RouterSign_ELP: "0xD067e4B0144841bc79153874d385671Ea4c4e4DF",
    EDE_LP: "0x7CA686B3795784f12643127c5c3F56aa107a04C3",
    Team: "0x96569ff8f1Eb073f0FFd5cCBf855b7A5431C3B2E",
    LP_Marketing: "0x730c4CD9499033DDb2BF5465098e108089b6F123",
    Contracts_Reserve: "0x00962B56fe689faCC6ec66D38867f1238304B18f",
    ede_burn: "0x000000000000000000000000000000000000dEaD",
    Marketing: "0xAF36E340E901c9864f7f215B9DeAc00aa87e4ea3",
    TokenVesting_pEDE2: "0x0000000000000000000000000000000000000001",
    LP_Farm: "0x8B691c7b57E3C7CF69cf2425Ec754C47D8Dc86d7",
  },
};
