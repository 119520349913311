import { SupportedChainId } from "./chainENV/chains.base";
import { addresses as ADDRESS } from "../configs/constants";
//  ELP 默认查询  数据列表
type ListProps = {
  name: ELP_TYPES;
  top: string;
  Elp_address: string;
  ElpManager_address: string;
  rewardTracker_address: string;
  isOld?: boolean; // 是否是旧版elp
  vault_address: string;
  positionRouter_address: string;
  orderBook_address: string;
  router_address: string;
  positionManager_address: string;
  isGNS?: boolean; // 是否是gns 模式
  routerSign_adddress?: string; // 新版签名模式
  vaultUtil_addr?: string; // 新版查询
};

export enum ELP_TYPES {
  ELP = "ELP-1P",
  ELP2 = "ELP-1",
  ELP3 = "ELP-3",
}
export const ELP_LIST: { [key: number]: ListProps[] } = {
  [SupportedChainId.BSCTEST]: [
    {
      name: ELP_TYPES.ELP,
      top: "Top 5 ETF",
      Elp_address: ADDRESS[SupportedChainId.BSCTEST].ELP_1p,
      ElpManager_address: ADDRESS[SupportedChainId.BSCTEST].ElpManager_ELP_1p,
      rewardTracker_address:
        ADDRESS[SupportedChainId.BSCTEST].RewardTracker_ELP_1p,
      vault_address: ADDRESS[SupportedChainId.BSCTEST].Vault_ELP_1p,
      positionRouter_address:
        ADDRESS[SupportedChainId.BSCTEST].PositionRouter_ELP_1p,
      orderBook_address: ADDRESS[SupportedChainId.BSCTEST].OrderBook_ELP_1p,
      router_address: ADDRESS[SupportedChainId.BSCTEST].Router_ELP_1p,
      positionManager_address:
        ADDRESS[SupportedChainId.BSCTEST].PositionManager_ELP_1p,
      routerSign_adddress: ADDRESS[SupportedChainId.BSCTEST].RouterSign_ELP_1p,
      vaultUtil_addr: ADDRESS[SupportedChainId.BSCTEST].VaultUtils_ELP_1p,
    },
    {
      name: ELP_TYPES.ELP3,
      top: "Top 50 ETF",
      Elp_address: ADDRESS[SupportedChainId.BSCTEST].ELP_3,
      ElpManager_address: ADDRESS[SupportedChainId.BSCTEST].ElpManager_ELP_3,
      rewardTracker_address:
        ADDRESS[SupportedChainId.BSCTEST].RewardTracker_ELP_3,
      vault_address: ADDRESS[SupportedChainId.BSCTEST].Vault_ELP_3,
      positionRouter_address:
        ADDRESS[SupportedChainId.BSCTEST].PositionRouter_ELP_3,
      orderBook_address: ADDRESS[SupportedChainId.BSCTEST].OrderBook_ELP_3,
      router_address: ADDRESS[SupportedChainId.BSCTEST].Router_ELP_3,
      positionManager_address:
        ADDRESS[SupportedChainId.BSCTEST].PositionManager_ELP_3,
      routerSign_adddress: ADDRESS[SupportedChainId.BSCTEST].RouterSign_ELP_3,
      isGNS: true,
      vaultUtil_addr: ADDRESS[SupportedChainId.BSCTEST].VaultUtils_ELP_3,
    },
  ],
  [SupportedChainId.BSCMAINNET]: [
    {
      name: ELP_TYPES.ELP,
      top: "Top 5 ETF",
      Elp_address: ADDRESS[SupportedChainId.BSCMAINNET].ELP_1p,
      ElpManager_address:
        ADDRESS[SupportedChainId.BSCMAINNET].ElpManager_ELP_1p,
      rewardTracker_address:
        ADDRESS[SupportedChainId.BSCMAINNET].RewardTracker_ELP_1p,
      vault_address: ADDRESS[SupportedChainId.BSCMAINNET].Vault_ELP_1p,
      positionRouter_address:
        ADDRESS[SupportedChainId.BSCMAINNET].PositionRouter_ELP_1p,
      orderBook_address: ADDRESS[SupportedChainId.BSCMAINNET].OrderBook_ELP_1p,
      router_address: ADDRESS[SupportedChainId.BSCMAINNET].Router_ELP_1p,
      positionManager_address:
        ADDRESS[SupportedChainId.BSCMAINNET].PositionManager_ELP_1p,
      routerSign_adddress:
        ADDRESS[SupportedChainId.BSCMAINNET].RouterSign_ELP_1p,
      vaultUtil_addr: ADDRESS[SupportedChainId.BSCMAINNET].VaultUtils_ELP_1p,
    },
    // {
    //   name: "ELP-1",
    //   top: "Top 5 ETF",
    //   Elp_address: ADDRESS[SupportedChainId.BSCMAINNET].ELP,
    //   ElpManager_address: ADDRESS[SupportedChainId.BSCMAINNET].ElpManager,
    //   rewardTracker_address: ADDRESS[SupportedChainId.BSCMAINNET].RewardTracker,
    //   vault_address: ADDRESS[SupportedChainId.BSCMAINNET].Vault,
    //   positionRouter_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].PositionRouter,
    //   orderBook_address: ADDRESS[SupportedChainId.BSCMAINNET].OrderBook,
    //   router_address: ADDRESS[SupportedChainId.BSCMAINNET].Router,
    //   positionManager_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].PositionManager,
    // },
    // {
    //   name: "ELP-2",
    //   top: "Top 50 ETF",
    //   Elp_address: ADDRESS[SupportedChainId.BSCMAINNET].ELP_2,
    //   ElpManager_address: ADDRESS[SupportedChainId.BSCMAINNET].ElpManager_ELP_2,
    //   rewardTracker_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].RewardTracker_ELP_2,
    //   vault_address: ADDRESS[SupportedChainId.BSCMAINNET].Vault_ELP_2,
    //   positionRouter_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].PositionRouter_ELP_2,
    //   orderBook_address: ADDRESS[SupportedChainId.BSCMAINNET].OrderBook_ELP_2,
    //   router_address: ADDRESS[SupportedChainId.BSCMAINNET].Router_ELP_2,
    //   positionManager_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].PositionManager_ELP_2,
    // },
  ],
  [SupportedChainId.ARB_TEST]: [
    {
      name: ELP_TYPES.ELP2,
      top: "Top 5 ETF",
      Elp_address: ADDRESS[SupportedChainId.ARB_TEST].ELP,
      ElpManager_address: ADDRESS[SupportedChainId.ARB_TEST].ElpManager,
      rewardTracker_address: ADDRESS[SupportedChainId.ARB_TEST].RewardTracker,
      vault_address: ADDRESS[SupportedChainId.ARB_TEST].Vault,
      positionRouter_address: ADDRESS[SupportedChainId.ARB_TEST].PositionRouter,
      orderBook_address: ADDRESS[SupportedChainId.ARB_TEST].OrderBook,
      router_address: ADDRESS[SupportedChainId.ARB_TEST].Router,
      positionManager_address:
        ADDRESS[SupportedChainId.ARB_TEST].PositionManager,
      routerSign_adddress: ADDRESS[SupportedChainId.ARB_TEST].RouterSign_ELP,
      vaultUtil_addr: ADDRESS[SupportedChainId.ARB_TEST].VaultUtils,
    },
  ],
  [SupportedChainId.ARBITRUM]: [
    {
      name: ELP_TYPES.ELP2,
      top: "Top 5 ETF",
      Elp_address: ADDRESS[SupportedChainId.ARBITRUM].ELP,
      ElpManager_address: ADDRESS[SupportedChainId.ARBITRUM].ElpManager,
      rewardTracker_address: ADDRESS[SupportedChainId.ARBITRUM].RewardTracker,
      vault_address: ADDRESS[SupportedChainId.ARBITRUM].Vault,
      positionRouter_address: ADDRESS[SupportedChainId.ARBITRUM].PositionRouter,
      orderBook_address: ADDRESS[SupportedChainId.ARBITRUM].OrderBook,
      router_address: ADDRESS[SupportedChainId.ARBITRUM].Router,
      positionManager_address:
        ADDRESS[SupportedChainId.ARBITRUM].PositionManager,
      routerSign_adddress: ADDRESS[SupportedChainId.ARBITRUM].RouterSign_ELP,
      vaultUtil_addr: ADDRESS[SupportedChainId.ARBITRUM].VaultUtils,
    },
  ],
};
