import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";
import { isAddress } from "src/utils";
import { useWeb3Context } from "./web3Context";
import { addresses as ADDRESSMAP } from "../configs/constants";
import { abi as PositionReaderABI } from "../abis/PositionReader.json";
import { abi as VaultReaderABI } from "../abis/VaultReader.json";
import { abi as VaultPriceFeedV21FastABi } from "../abis/VaultPriceFeedV21Fast.json";
import { abi as VaultUtilsABI } from "../abis/VaultUtils.json";
import { abi as ElpTransferHelperABI } from "../abis/ElpTransferHelper.json";
import { abi as TokenABI } from "../abis/Token.json";
import { abi as PairABI } from "../abis/Pair.json";
import { abi as RouterABI } from "../abis/Router.json";

import { abi as RouterSignAbi } from "../abis/RouterSign.json";
import { abi as OrderBookABI } from "../abis/OrderBook.json";
import { abi as OrderBookReaderABI } from "../abis/OrderBookReader.json";
import { UniswapInterfaceMulticall } from "@uniswap/redux-multicall/dist/abi/types";
import UniswapInterfaceMulticallJson from "@uniswap/v3-periphery/artifacts/contracts/lens/UniswapInterfaceMulticall.sol/UniswapInterfaceMulticall.json";

import {
  AddressMap,
  AddressZero,
  MULTICALL_ADDRESS,
} from "../constants/address";
import {
  PositionReader,
  VaultV2,
  VaultReader,
  VaultUtils,
  VaultPriceFeedV21Fast,
  Token,
  RouterSign,
  Pair,
  ElpTransferHelper,
  EUSD,
  EDE,
  IERC20,
  EdeStake,
  ELP,
  RewardTracker,
  GEdeYieldDistributor,
  RewardRouter,
  InfoHelper,
  Reader,
  OrderBook,
  OrderBookReader,
  Router,
  TokenVesting_pEDE,
  TokenVesting_EDE,
} from "src/abis/types";

import { abi as TokenVestingABI_EDE } from "../abis/TokenVesting_EDE.json";
import { abi as TokenVestingABI_pEDE } from "../abis/TokenVesting_pEDE.json";
import { abi as EUSDABI } from "src/abis/EUSD.json";
import { abi as EDEABI } from "src/abis/EDE.json";
import { abi as EdeStakeABI } from "../abis/edeStake.json";
import { abi as ReaderABI } from "../abis/ReaderV2.json";
import { abi as VaultV2ABI } from "../abis/VaultV2.json";
import { abi as IERC20ABI } from "../abis/IERC20.json";
import { abi as ElpABI } from "../abis/ELP.json";
import { abi as RewardTrackerABI } from "../abis/RewardTracker.json";
import { abi as gEdeYieldDistributorABI } from "../abis/gEdeYieldDistributor.json";
import { abi as RewardRouterABI } from "../abis/RewardRouter.json";
import { ethers } from "ethers";
import { addresses as ADDRESS } from "../configs/constants";
import { abi as InfoHelperABI } from "../abis/InfoHelper.json";
const { abi: MulticallABI } = UniswapInterfaceMulticallJson;
export function useContract<T extends Contract = Contract>(
  _address: AddressMap | string | undefined,
  ABI: any[]
): T | undefined {
  const { chainID: chainId, provider, address: account } = useWeb3Context();
  const signer = provider?.getSigner();
  const address = useMemo(() => {
    if (!chainId) return;
    return typeof _address == "string"
      ? _address
      : _address
      ? _address[chainId]
      : undefined;
  }, [_address, chainId]);

  return useMemo(() => {
    if (!provider || !address) return;

    if (!isAddress(address) || address === AddressZero) {
      throw Error(`Invalid 'address' parameter '${address}'.`);
    }

    let contract;
    if (!account) {
      // If you are not logged in, you need to display data. Add an account to simulate real data
      const wallet = new ethers.Wallet(
        "c977f87f97177d99ddf7e16bcd27bb4e2558d927831d64b2557ac772c3ddded5",
        provider
      );
      contract = new Contract(address, ABI, wallet.provider);
    } else {
      contract = new Contract(address, ABI, signer ? signer : undefined);
    }
    return contract;
  }, [provider, address, ABI, account, signer]) as T;
}

export const usePositionReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<PositionReader>(
    ADDRESSMAP[chainID]?.PositionReader,
    PositionReaderABI
  );
};

export const useOrderBookContract = (orderBookAddress: string) => {
  return useContract<OrderBook>(orderBookAddress, OrderBookABI);
};

export const useOrderBookReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<OrderBookReader>(
    ADDRESSMAP[chainID]?.OrderBookReader,
    OrderBookReaderABI
  );
};

export const useVaultV2Contract = (vaultAddress: string) => {
  return useContract<VaultV2>(vaultAddress, VaultV2ABI);
};

export const useVaultReaderContract = () => {
  const { chainID } = useWeb3Context();
  return useContract<VaultReader>(
    ADDRESSMAP[chainID]?.VaultReader,
    VaultReaderABI
  );
};

export function useInterfaceMulticall() {
  return useContract<UniswapInterfaceMulticall>(
    MULTICALL_ADDRESS,
    MulticallABI
  ) as UniswapInterfaceMulticall;
}

export function useVaultUtilsContract(vaultUtilsAddress: string) {
  return useContract<VaultUtils>(vaultUtilsAddress, VaultUtilsABI);
}

export function useElpTransferHelperContract() {
  const { chainID } = useWeb3Context();
  return useContract<ElpTransferHelper>(
    ADDRESSMAP[chainID]?.ElpTransferHelper,
    ElpTransferHelperABI
  );
}

export function useVaultPriceFeedV2Fast() {
  const { chainID } = useWeb3Context();
  return useContract<VaultPriceFeedV21Fast>(
    ADDRESSMAP[chainID]?.VaultPriceFeedV2Fast,
    VaultPriceFeedV21FastABi
  );
}

export function useTokenContract(address?: string) {
  return useContract<Token>(address, TokenABI);
}

export function useRouterSignContract(address?: string) {
  return useContract<RouterSign>(address, RouterSignAbi);
}

export function usePairContract(address?: string) {
  return useContract<Pair>(address, PairABI);
}

export function useEusdContract() {
  const { chainID } = useWeb3Context();
  return useContract<EUSD>(ADDRESS[chainID].EUSD, EUSDABI);
}

export function useEDEContract() {
  const { chainID } = useWeb3Context();
  return useContract<EDE>(ADDRESS[chainID].EDE, EDEABI);
}

export function useVeStakerContract() {
  const { chainID } = useWeb3Context();
  return useContract<EdeStake>(ADDRESS[chainID].veStaker, EdeStakeABI);
}

export function useAEDEContract() {
  const { chainID } = useWeb3Context();
  return useContract<IERC20>(ADDRESS[chainID].aEDE, IERC20ABI);
}

export function useELPContract(address?: string) {
  return useContract<ELP>(address, ElpABI);
}

export function useTokenVestingContract_pEDE() {
  const { chainID } = useWeb3Context();
  return useContract<TokenVesting_pEDE>(
    ADDRESS[chainID].TokenVesting_pEDE,
    TokenVestingABI_pEDE
  );
}

export function useTokenVestingContract_EDE() {
  const { chainID } = useWeb3Context();
  return useContract<TokenVesting_EDE>(
    ADDRESS[chainID].TokenVesting_EDE,
    TokenVestingABI_EDE
  );
}

export function useRewardTrackerContract(address?: string) {
  return useContract<RewardTracker>(address, RewardTrackerABI);
}

export function useEdeDistributionContract() {
  const { chainID } = useWeb3Context();
  return useContract<GEdeYieldDistributor>(
    ADDRESS[chainID].edeDistribution,
    gEdeYieldDistributorABI
  );
}

export function useEusdDistributionContract() {
  const { chainID } = useWeb3Context();
  return useContract<GEdeYieldDistributor>(
    ADDRESS[chainID].eusdDistribution,
    gEdeYieldDistributorABI
  );
}

export function useRewardRouterContract() {
  const { chainID } = useWeb3Context();
  return useContract<RewardRouter>(
    ADDRESS[chainID].RewardRouter,
    RewardRouterABI
  );
}

export function useInfoHelperContract() {
  const { chainID } = useWeb3Context();
  return useContract<InfoHelper>(ADDRESS[chainID].InfoHelper, InfoHelperABI);
}

export function useReaderContract() {
  const { chainID } = useWeb3Context();
  return useContract<Reader>(ADDRESS[chainID].Reader, ReaderABI);
}

export function useRouterContract(address?: string) {
  return useContract<Router>(address, RouterABI);
}
