import { ethers } from "ethers";
import { getContractAddress } from "../Addresses";

import BTC_icon from "../assets/images/tokens/BTC.svg";
import BNB_icon from "../assets/images/tokens/BNB.svg";
import BUSD_icon from "../assets/images/tokens/BUSD.svg";
import ETH_icon from "../assets/images/tokens/ETH.svg";
import ADA_icon from "../assets/images/tokens/ADA.svg";
import CAKE_icon from "../assets/images/tokens/CAKE.svg";
import ARB_icon from "../assets/images/tokens/Arbitrum.svg";
import LINK_icon from "../assets/images/tokens/LINK.svg";
import XRP_icon from "../assets/images/tokens/XRP.svg";
import USDC_icon from "../assets/images/tokens/USDC.svg";
import USDT_icon from "../assets/images/tokens/USDT.svg";
import DAI_icon from "../assets/images/tokens/DAI.svg";
import DOGE_icon from "../assets/images/tokens/DOGE.svg";
import DOT_icon from "../assets/images/tokens/DOT.svg";
import FIL_icon from "../assets/images/tokens/FIL.svg";
import GMT_icon from "../assets/images/tokens/GMT.svg";
import INJ_icon from "../assets/images/tokens/INJ.svg";
import LTC_icon from "../assets/images/tokens/LTC.svg";
import MATIC_icon from "../assets/images/tokens/MATIC.svg";
import MBOX_icon from "../assets/images/tokens/MBOX.svg";
import REEF_icon from "../assets/images/tokens/REEF.svg";
import SXP_icon from "../assets/images/tokens/SXP.svg";
import TRX_icon from "../assets/images/tokens/TRX.svg";
import XAU_icon from "../assets/images/tokens/XAU.svg";
import XVS_icon from "../assets/images/tokens/XVS.svg";

const TOKENS = {
  97: [
    {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      imageUrl: BNB_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#F0B90B",
    },
    {
      name: "BNB (Wrapped)",
      symbol: "WBNB",
      baseSymbol: "BNB",
      decimals: 18,
      address: getContractAddress(97, "WBNB"),
      imageUrl: BNB_icon,
      isShortable: false,
      isWrapped: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#F0B90B",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      address: getContractAddress(97, "BTC"),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: getContractAddress(97, "ETH"),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: getContractAddress(97, "BUSD"),
      imageUrl: BUSD_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#AC8300",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: getContractAddress(97, "USDT"),
      imageUrl: USDT_icon,
      isShortable: true,
      isStable: true,
      isElp3: true,
      tokenDecimals: 2,
      bgcolor: "#53AE94",
    },

  ],
  56: [
    {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      imageUrl: BNB_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
      bgcolor: "#F0B90B",
    },
    {
      name: "BNB (Wrapped)",
      symbol: "WBNB",
      baseSymbol: "BNB",
      decimals: 18,
      address: getContractAddress(56, "WBNB"),
      imageUrl: BNB_icon,
      isShortable: false,
      isWrapped: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
      bgcolor: "#F0B90B",
    },
    {
      name: "Binance USD",
      symbol: "BUSD",
      decimals: 18,
      address: getContractAddress(56, "BUSD"),
      imageUrl: BUSD_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      isElp2: true,
      tokenDecimals: 2,
      bgcolor: "#AC8300",
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: getContractAddress(56, "USDT"),
      imageUrl: USDT_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
      bgcolor: "#53AE94",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 18,
      address: getContractAddress(56, "BTC"),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: getContractAddress(56, "ETH"),
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      isElp1p: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
    },
    // {
    //   name: "Dogecoin",
    //   symbol: "DOGE",
    //   decimals: 18,
    //   address: getContractAddress(56, "DOGE"),
    //   imageUrl: DOGE_icon,
    //   isElp2: true,
    //   isLongable: true,
    //   isShortable: true,
    //   tokenDecimals: 4,
    // },
    {
      name: "Cardano",
      symbol: "ADA",
      decimals: 18,
      address: getContractAddress(56, "ADA"),
      imageUrl: ADA_icon,
      // isLongable: true,
      // isShortable: true,
      // isElp2: true,
      tokenDecimals: 4,
      bgcolor: "#0033AD",
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 18,
      address: getContractAddress(56, "XRP"),
      imageUrl: XRP_icon,
      // isShortable: true,
      // isLongable: true,
      // isElp2: true,
      tokenDecimals: 4,
      bgcolor: "#58626C",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      address: getContractAddress(56, "LINK"),
      imageUrl: LINK_icon,
      // isShortable: true,
      // isLongable: true,
      // isElp2: true,
      tokenDecimals: 4,
      bgcolor: "#335DD2",
    },
    {
      name: "PancakeSwap",
      symbol: "CAKE",
      decimals: 18,
      address: getContractAddress(56, "CAKE"),
      imageUrl: CAKE_icon,
      // isShortable: true,
      // isLongable: true,
      // isElp2: true,
      tokenDecimals: 4,
      bgcolor: "#43D7E1",
    },
  ],
  421613: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(421613, "WETH"),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(421613, "BTC"),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 6,
      address: getContractAddress(421613, "USDC"),
      imageUrl: USDC_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
    },
    {
      name: "USDT",
      symbol: "USDT",
      decimals: 6,
      address: getContractAddress(421613, "USDT"),
      imageUrl: USDT_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#53AE94",
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: getContractAddress(421613, "DAI"),
      imageUrl: DAI_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",
    },
  ],
  42161: [
    {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
      isNative: true,
      address: ethers.constants.AddressZero,
      imageUrl: ETH_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
    },
    {
      name: "ETH (Wrapped)",
      symbol: "WETH",
      baseSymbol: "ETH",
      decimals: 18,
      address: getContractAddress(42161, "WETH"),
      imageUrl: ETH_icon,
      isShortable: false,
      isWrapped: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#6F7BBA",
    },
    {
      name: "Arbitrum",
      symbol: "ARB",
      decimals: 18,
      address: getContractAddress(42161, "ARB"),
      imageUrl: ARB_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 4,
      bgcolor: "#289FEF",
    },
    {
      name: "Bitcoin",
      symbol: "BTC",
      decimals: 8,
      address: getContractAddress(42161, "BTC"),
      imageUrl: BTC_icon,
      isShortable: true,
      isLongable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#F7931A",
    },
    {
      name: "USDC",
      symbol: "USDC",
      decimals: 6,
      address: getContractAddress(42161, "USDC"),
      imageUrl: USDC_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#2775CA",
    },
    {
      name: "USDT",
      symbol: "USDT",
      decimals: 6,
      address: getContractAddress(42161, "USDT"),
      imageUrl: USDT_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#53AE94",
    },
    {
      name: "DAI",
      symbol: "DAI",
      decimals: 18,
      address: getContractAddress(42161, "DAI"),
      imageUrl: DAI_icon,
      isShortable: true,
      isStable: true,
      isElp1: true,
      tokenDecimals: 2,
      bgcolor: "#F9AF1B",
    },
  ]
};


export const WHITE_GNS_TOKEN_ADDRESS = (chainId) => {

  return [
    {
      name: "Dogecoin",
      symbol: "DOGE",
      decimals: 18,
      address: getContractAddress(chainId, "DOGE"),
      imageUrl: DOGE_icon,
      isElp3: true,
      isLongable: true,
      isShortable: true,
      tokenDecimals: 4,
      bgcolor: "#cfb66c",
    },
    {
      name: "XRP",
      symbol: "XRP",
      decimals: 18,
      address: getContractAddress(chainId, "XRP"),
      imageUrl: XRP_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#58626C",
    },
    {
      name: "Cardano",
      symbol: "ADA",
      decimals: 18,
      address: getContractAddress(chainId, "ADA"),
      imageUrl: ADA_icon,
      isLongable: true,
      isShortable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#0033AD",
    },
    {
      name: "Chainlink",
      symbol: "LINK",
      decimals: 18,
      address: getContractAddress(chainId, "LINK"),
      imageUrl: LINK_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#335DD2",
    },
    {
      name: "PancakeSwap",
      symbol: "CAKE",
      decimals: 18,
      address: getContractAddress(chainId, "CAKE"),
      imageUrl: CAKE_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#43D7E1",
    },
    {
      name: "Polkadot",
      symbol: "DOT",
      decimals: 18,
      address: getContractAddress(chainId, "DOT"),
      imageUrl: DOT_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#E6007A",
    },
    {
      name: "Filecoin",
      symbol: "FIL",
      decimals: 18,
      address: getContractAddress(chainId, "FIL"),
      imageUrl: FIL_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#0090FF",
    },
    {
      name: "STEPN",
      symbol: "GMT",
      decimals: 18,
      address: getContractAddress(chainId, "GMT"),
      imageUrl: GMT_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#DCB955",
    },
    {
      name: "Injective",
      symbol: "INJ",
      decimals: 18,
      address: getContractAddress(chainId, "INJ"),
      imageUrl: INJ_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#0EFFE0",
    },
    {
      name: "Litecoin",
      symbol: "LTC",
      decimals: 18,
      address: getContractAddress(chainId, "LTC"),
      imageUrl: LTC_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#345D9D",
    },
    {
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18,
      address: getContractAddress(chainId, "MATIC"),
      imageUrl: MATIC_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#8247E5",
    },
    {
      name: "MOBOX",
      symbol: "MBOX",
      decimals: 18,
      address: getContractAddress(chainId, "MBOX"),
      imageUrl: MBOX_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#006BFD",
    },
    {
      name: "Reef",
      symbol: "REEF",
      decimals: 18,
      address: getContractAddress(chainId, "REEF"),
      imageUrl: REEF_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#CF05C8",
    },
    {
      name: "Solar",
      symbol: "SXP",
      decimals: 18,
      address: getContractAddress(chainId, "SXP"),
      imageUrl: SXP_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#FF3B3B",
    },
    {
      name: "TRON",
      symbol: "TRX",
      decimals: 18,
      address: getContractAddress(chainId, "TRX"),
      imageUrl: TRX_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#FF060A",
    },
    {
      name: "Venus",
      symbol: "XVS",
      decimals: 18,
      address: getContractAddress(chainId, "XVS"),
      imageUrl: XVS_icon,
      isShortable: true,
      isLongable: true,
      isElp3: true,
      tokenDecimals: 4,
      bgcolor: "#5433FF",
    }
  ]
}

const ADDITIONAL_TOKENS = {
  97: [
    {
      name: "ELP",
      symbol: "ELP",
      address: getContractAddress(97, "ELP_1p"),
      decimals: 18,
    },
  ],
  56: [
    {
      name: "ELP",
      symbol: "ELP",
      address: getContractAddress(56, "ELP"),
      decimals: 18,
    },
  ],
  421613: [
    {
      name: "ELP",
      symbol: "ELP",
      address: getContractAddress(421613, "ELP"),
      decimals: 18,
    },
  ],
  42161: [
    {
      name: "ELP",
      symbol: "ELP",
      address: getContractAddress(42161, "ELP"),
      decimals: 18,
    },
  ]
};

const CHAIN_IDS = [56, 97, 42161, 421613];
const TOKENS_MAP = {};
const TOKENS_BY_SYMBOL_MAP = {};

for (let j = 0;j < CHAIN_IDS.length;j++) {
  const chainId = CHAIN_IDS[j];
  TOKENS_MAP[chainId] = {};
  TOKENS_BY_SYMBOL_MAP[chainId] = {};
  let tokens = TOKENS[chainId];
  if (ADDITIONAL_TOKENS[chainId]) {
    tokens = tokens.concat(ADDITIONAL_TOKENS[chainId]);
  }

  for (let i = 0;i < tokens.length;i++) {
    const token = tokens[i];
    TOKENS_MAP[chainId][token.address] = token;
    TOKENS_BY_SYMBOL_MAP[chainId][token.symbol] = token;
  }
}

const WRAPPED_TOKENS_MAP = {};
const NATIVE_TOKENS_MAP = {};
for (const chainId of CHAIN_IDS) {
  for (const token of TOKENS[chainId]) {
    if (token.isWrapped) {
      WRAPPED_TOKENS_MAP[chainId] = token;
    } else if (token.isNative) {
      NATIVE_TOKENS_MAP[chainId] = token;
    }
  }
}

export function getWrappedToken(chainId) {
  return WRAPPED_TOKENS_MAP[chainId];
}

export function getNativeToken(chainId) {
  return NATIVE_TOKENS_MAP[chainId];
}

export function getTokens(chainId) {
  return TOKENS[chainId];
}

export function isValidToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  return address in TOKENS_MAP[chainId];
}

export function getToken(chainId, address) {
  if (!TOKENS_MAP[chainId]) {
    throw new Error(`Incorrect chainId ${chainId}`);
  }
  if (!TOKENS_MAP[chainId][address]) {
    throw new Error(`Incorrect address "${address}" for chainId ${chainId}`);
  }
  return TOKENS_MAP[chainId][address];
}

export function getTokenBySymbol(chainId, symbol) {
  const token = TOKENS_BY_SYMBOL_MAP[chainId][symbol];
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}" for chainId ${chainId}`);
  }
  return token;
}

export function getWhitelistedTokens(chainId) {
  return TOKENS[chainId].filter(token => token.symbol !== "USDX");
}

export function getWhitelistedTokens2(elpName, chainId) {
  const tokens = TOKENS[chainId]
  const token1 = tokens.filter(token => token.isElp1)
  const token2 = tokens.filter(token => token.isElp2)
  return elpName == "ELP-1" ? token1.filter(token => token.symbol !== "USDX") : token2.filter(token => token.symbol !== "USDX");
}

export function getTokenMeta(chainId, address) {
  let supportedTokens = TOKENS[chainId];
  let obj = supportedTokens.find(token => token.address.toLowerCase() === address.toLowerCase());

  return obj;
}
