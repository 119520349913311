import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";

export const SwapBtn = styled(Button)({
  height: "50px",
  lineHeight: "50px",
  maxHeight: "50px",
  width: "100%",
  color: "#FFFFFF",
  fontSize: 18,
  fontFamily: "Poppins-Bold",
  textTransform: "uppercase",
  background: "#F89542",
  border: "none",
  borderRadius: "8px",
  "&:hover": {
    background: "#FCA149",
    color: "#ffffff",
  },
  "&:disabled": {
    backgroundColor: "#7A7A7A!important",
    color: "#A4A4A4",
  },
});
