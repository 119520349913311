import { ethers } from "ethers";
import { useWeb3Context } from "./web3Context";
import { abi as IERC20ABI } from "../abis/IERC20.json";
import { useCallback } from "react";
import { clearPendingTxn, fetchPendingTxns } from "src/slices/PendingTxnsSlice";
import { useDispatch } from "react-redux";
import { serializeError } from "eth-rpc-errors";
import { toastError, toastSuccess } from "src/helpers/toastHelpers";
import { useTokenContract } from "./useContract";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { PLACEHOLDER_ACCOUNT } from "src/helpers/Helpers";
export default function useTokenApprove() {
  const { address, provider } = useWeb3Context();
  const dispatch = useDispatch();

  const getERC20Approved = async (
    tokenAddress: string,
    spenderAddress: string
  ) => {
    const tokenContract = new ethers.Contract(
      tokenAddress,
      IERC20ABI,
      provider
    );
    if (!tokenAddress || !spenderAddress) return false;

    const allowance = await tokenContract.allowance(
      address ? address : PLACEHOLDER_ACCOUNT,
      spenderAddress
    );
    return Number(allowance) > 0;
  };

  const approveToken = useCallback(
    async (tokenAddress: string, spenderAddress: string) => {
      if (provider && address) {
        let tx;
        try {
          const signer = provider.getSigner();
          const tokenContract = new ethers.Contract(
            tokenAddress,
            IERC20ABI,
            signer
          );
          tx = await tokenContract.approve(
            spenderAddress,
            ethers.constants.MaxUint256
          );
          dispatch(
            fetchPendingTxns({
              txnHash: tx.hash,
              text: "Approve",
              type: "approveToken",
            })
          );
          await tx.wait();
          return tx;
        } catch (e: unknown) {
          const rpcError: any = serializeError(e);
          // @ts-ignore
          rpcError.data && rpcError.data.originalError
            ? toastError(rpcError?.data?.originalError?.reason)
            : toastError(rpcError.message);
        } finally {
          if (tx) {
            dispatch(clearPendingTxn(tx.hash));
            // dispatch(success("Approve Success!"))
            toastSuccess("Approve Success!");
          }
        }
      }
    },
    [address, dispatch, provider]
  );

  return { getERC20Approved, approveToken };
}

export const useQueryApproved = (token?: string, spender?: string) => {
  const { address } = useWeb3Context();
  const tokenContract = useTokenContract(token);
  const { result: routerApporve } = useSingleCallResult(
    tokenContract,
    "allowance",
    [address ? address : PLACEHOLDER_ACCOUNT, spender ?? undefined]
  );

  // const approveToken = useCallback(async ()=>{

  // },[])

  return { Approved: routerApporve?.[0] };
};
